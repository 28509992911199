<template>
    <div>
        <header style="position: relative">
            <div class="sub-title">
                <div class="sub-text">主要食材采购情况</div>
                <p class="sub-text-en">
                    Trading situation of major agricultural products
                </p>
            </div>
            <button class="more-button" @click="showMore">More>></button>
        </header>
        <div style="position: relative">
            <Spin size="large" fix v-if="loading"></Spin>
            <v-chart
                style="width: 100%; height: 260px"
                :options="option"
            ></v-chart>
        </div>
        <Modal
            v-model="modal"
            title="食材采购情况明细"
            footer-hide
            width="1080px"
        >
            <div style="position: relative; text-align: center; height: 580px">
                <Spin size="large" fix v-if="loadingModal"></Spin>
                <LookMore
                    :seamlesstitle="modelTableTitle"
                    :seamlessbody="modelList"
                    :pagination="modelPagination"
                    @change="handelModelChange"
                ></LookMore>
            </div>
        </Modal>
    </div>
</template>
<script>
import { productApi } from "@/api";
import LookMore from "@/components/lookMore/index.vue";
export default {
    name: "food",
    components: {
        LookMore,
    },
    data() {
        return {
            modelPagination: {
                total: 0,
                current: 1,
                page_size: 10,
            },
            modal: false,
            loading: false,
            option: {},
            modelTableTitle: [
                {
                    title: "食材",
                    styles: { width: "22%" },
                    textcolor: "",
                    key: "name",
                },
                {
                    title: "采购数量",
                    styles: { width: "12%" },
                    textcolor: "#1E97DB",
                    key: "purchase_num",
                },
                {
                    title: "采购金额（万元）",
                    styles: { width: "14%" },
                    textcolor: "",
                    key: "purchase_money",
                },
                {
                    title: "发货数量",
                    styles: { width: "12%" },
                    textcolor: "#1E97DB",
                    key: "shipping_num",
                },
                {
                    title: "发货金额（万元）",
                    styles: { width: "14%" },
                    textcolor: "",
                    key: "shipping_money",
                },
                {
                    title: "收货数量",
                    styles: { width: "12%" },
                    textcolor: "#FFDB67",
                    key: "reciver_num",
                },
                {
                    title: "收货金额（万元）",
                    styles: { width: "14%" },
                    textcolor: "#0FE5F0",
                    key: "reciver_money",
                },
            ],
            loadingModal: false,
            modelList: [],
        };
    },

    methods: {
        handelModelChange(e) {
            this.modelPagination.current = e;
            this.getDataList();
        },
       
        setOptions(xData, valueList) {
            this.option = {
                grid: {
                    right: 80,
                    top: 30,
                    bottom: 30,
                    left:115,
                    // containLabel:true
                },
                xAxis: {
                    axisTick: { show: false },
                    axisLabel: {
                        show: false,
                    },
                    axisLine: {
                        show: false,
                    },
                    type: "value",
                    splitLine: { show: false },
                    name: "单位：万元",
                    nameTextStyle: {
                        color: "#E2E4EB",
                    },
                    position: "top",
                },
                yAxis: {
                    data: xData,
                    splitLine: { show: false },
                    type: "category",
                    axisLabel: {
                        color: "#9B9CA5",
                    },
                },
                animationDurationUpdate: 1200,
                series: [
                    {
                        type: "bar",
                        itemStyle: {
                            normal: {
                                color: "#05328A",
                            },
                        },
                        barWidth: 14,
                        silent: true,
                        barGap: "-100%", // Make series be overlap
                        data: [60, 60, 60, 60, 60],
                    },
                    {
                        type: "bar",
                        z: 10,
                        barWidth: 14,
                        label: {
                            show: true,
                            position: "right",
                            color: "#E2E4EB",
                        },
                        itemStyle: {
                            normal: {
                                color: {
                                    type: "linear",
                                    x: 0,
                                    y: 0,
                                    x2: 1,
                                    y2: 0,
                                    colorStops: [
                                        {
                                            offset: 0,
                                            color: "#BAFF57", // 0% 处的颜色
                                        },
                                        {
                                            offset: 1,
                                            color: "#02FFFE", // 100% 处的颜色
                                        },
                                    ],
                                    global: false, // 缺省为 false
                                },
                            },
                        },
                        data: valueList,
                    },
                ],
            };
        },

        async getDataList() {
            try {
                this.loadingModal = true;
                const staticData = {
                    page: this.modelPagination.current,
                    limit: 10,
                };
                if (this.$route.query.city_id) {
                    staticData.city_id = this.$route.query.city_id;
                }
                if (this.$route.query.area_id) {
                    staticData.area_id = this.$route.query.area_id;
                }
                const resData = await productApi(staticData);
                this.modelPagination.total = Number(resData.count);
                this.modelList = resData.data||[];
            } finally {
                this.loadingModal = false;
            }
        },

        async initData() {
            try {
                this.loading = true;
                const staticData = { page: 1, limit: 5 };
                if (this.$route.query.city_id) {
                    staticData.city_id = this.$route.query.city_id;
                }
                if (this.$route.query.area_id) {
                    staticData.area_id = this.$route.query.area_id;
                }

                const resData = await productApi(staticData);
                const xData = resData.data.map((item) => item.name);
                const valueList = resData.data.map(
                    (item) => item.purchase_money
                );
                this.setOptions(xData.reverse(), valueList.reverse());
            } finally {
                this.loading = false;
            }
        },
        showMore() {
            this.modal = true;
        },
    },
    mounted() {
        this.initData();
        this.getDataList();
    },
    created() {},
};
</script>
<style>
.echarts {
    width: 100%;
    height: 100%;
}
</style>
