<template>
    <div>
        <header style="position: relative">
            <div class="sub-title">
                <div class="sub-text">近12个月食材采购额走势图</div>
                <p class="sub-text-en">
                    Trend chart of food procurement in the past 12 months
                </p>
            </div>
        </header>
        <div style="position: relative">
            <Spin size="large" fix v-if="loading"></Spin>
            <v-chart
                style="width: 100%; height: 320px"
                :options="option"
            ></v-chart>
        </div>
    </div>
</template>
<script>
import { monthAmtApi } from "@/api";
export default {
    name: "tradingCurve",
    data() {
        return {
            infoData: [],
            loading: false,
            option: {},
        };
    },
    methods: {
        async initData() {
            try {
                this.loading = true;
                const staticData = {};
                if (this.$route.query.city_id) {
                    staticData.city_id = this.$route.query.city_id;
                }
                if (this.$route.query.area_id) {
                    staticData.area_id = this.$route.query.area_id;
                }

                const resData = await monthAmtApi(staticData);
                this.infoData = resData.data;
                const xData = resData.data.map((item) => item.date_month);
                const valueList = resData.data.map(
                    (item) => item.shipping_money
                );
                this.option = {
                    grid: {
                        left: 60,
                        bottom: 40,
                        right: 20,
                    },
                    tooltip: {
                        trigger: "axis",
                        axisPointer: {
                            type: "cross",
                            label: {
                                backgroundColor: "#6a7985",
                            },
                        },
                        formatter: "{b}:<br />成交量：{c}",
                    },
                    xAxis: {
                        data: xData,
                        splitLine: { show: false },
                        type: "category",
                        axisLabel: {
                            color: "#E4E6F0",
                            interval: 0,
                        },
                        axisLine: {
                            lineStyle: {
                                color: "#6D7498",
                            },
                        },
                        axisLabel:{
                            interval:0,
                            rotate:37,
                        },
                        axisTick: {
                            show: false,
                        },
                    },
                    yAxis: {
                        axisTick: {
                            show: false,
                        },
                        axisLabel: {
                            color: "#6D7498",
                        },
                        axisLine: {
                            lineStyle: {
                                color: "#6D7498",
                            },
                        },
                        type: "value",
                        splitLine: { show: false },
                        name: "单位/万元",
                        nameTextStyle: {
                            color: "#fff",
                        },
                        position: "top",
                    },

                    animationDurationUpdate: 1200,
                    series: [
                        {
                            type: "line",
                            itemStyle: {
                                normal: {
                                    color: "#00FFFF",
                                },
                            },
                            areaStyle: {
                                color: {
                                    type: "linear",
                                    x: 0,
                                    y: 0,
                                    x2: 0,
                                    y2: 1,
                                    colorStops: [
                                        {
                                            offset: 0,
                                            color: "rgba(0,0,251,0.7)", // 0% 处的颜色
                                        },
                                        {
                                            offset: 1,
                                            color: "rgba(0,0,251,0)", // 100% 处的颜色
                                        },
                                    ],
                                    global: false, // 缺省为 false
                                },
                            },
                            data: valueList,
                        },
                    ],
                };
            } finally {
                this.loading = false;
            }
        },
    },
    mounted() {
        this.initData();
    },
};
</script>
<style>
.echarts {
    width: 100%;
    height: 100%;
}
</style>
