<template>
    <div>
        <div class="year-data">
            
            <span class="total-label" style="margin-right: -20px;">累计采购额</span>
            <countTo
                prefix="￥"
                :decimals="decimals"
                :startVal="0"
                :endVal="parseFloat(totalData.total_amt)"
                :duration="8000"
            ></countTo>
        </div>
        <div class="data-item-box clear-float">
            <div class="data-item">
                <span class="total-label">本年</span>
                <countTo
                    prefix="￥"
                    :decimals="decimals"
                    :startVal="0"
                    :endVal="parseFloat(totalData.year_amt)"
                    :duration="4000"
                ></countTo>
            </div>
            <div class="data-item">
                <span class="total-label">本月</span>
                <countTo
                    prefix="￥"
                    :decimals="decimals"
                    :startVal="0"
                    :endVal="parseFloat(totalData.month_amt)"
                    :duration="4000"
                ></countTo>
            </div>
            <div class="data-item">
                <span class="total-label">本日</span>
                <countTo
                    prefix="￥"
                    :decimals="decimals"
                    :startVal="0"
                    :endVal="parseFloat(totalData.day_amt)"
                    :duration="4000"
                ></countTo>
            </div>
        </div>
        <Spin size="large" fix v-if="loading"></Spin>
    </div>
</template>
<script>
import { amtApi } from "@/api";
export default {
    name: "total",
    components: {},
    props: {},
    data() {
        return {
            decimals: 2,
            loading: false,
            totalData: {
                day_amt: 0,
                month_amt: 0,
                total_amt: 0,
                year_amt: 0,
            },
        };
    },
    mounted() {
        this.initData();
    },
    methods: {
        async initData() {
            try {
                this.loading = true;
                const staticData = {};
                if (this.$route.query.city_id) {
                    staticData.city_id = this.$route.query.city_id;
                }
                if (this.$route.query.area_id) {
                    staticData.area_id = this.$route.query.area_id;
                }
                const resData = await amtApi(staticData);
                // this.totalData = resData.data;
                const { day_amt, month_amt, total_amt, year_amt } =
                    resData.data;
                    this.totalData=resData.data
                // this.totalData = {
                //     day_amt: day_amt.toFixed(2),
                //     month_amt: month_amt.toFixed(2),
                //     total_amt: total_amt.toFixed(2),
                //     year_amt: year_amt.toFixed(2),
                // };
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>
<style type="text/css"></style>
