<template>
    <div>
        <div class="modelChange">
          
             <div>
                <div class="table-box">
                     <div class="table-header clear-float ">
                        <span class="table-item" v-for="(item,index) in seamlesstitle" :key="index" :style="item.styles"  style="padding-left: 26px;">{{item.title}}</span>
                     </div>
                     
                     <div class="table-warp" :style="'height:'+heightBody">
                         <ul>
                             <li class="clear-float" v-for="(item,index) in seamlessbody" :key="index"  >
                                 <div class="table-item" :style="itemcollomn.styles"  v-for="(itemcollomn,indexcollomn) in seamlesstitle" style="padding-left: 26px;" :key="indexcollomn">
                                     <div v-if="itemcollomn.slot">
                                         <slot :name="itemcollomn.slot" :item="item" :index="index"></slot>	
                                     </div>
                                     <div :style="`color: ${itemcollomn.textcolor};`" v-else>
                                          
                                          <span v-if="item[itemcollomn.key]">
                                              {{item[itemcollomn.key]}}
                                          </span>
                                          <span v-else>
                                              --
                                          </span>
                                     </div>
                                 </div>
                             </li>
                         </ul>
                     </div>
                 </div>
                 
                 <div style="margin: 10px;overflow: hidden" v-if="pagination.current">
                         <div style="float: right;">
                             <Page :total="pagination.total" :current="pagination.current" :page-size="pagination.page_size" @on-change="changePage" show-total prev-text="上一页" next-text="下一页" ></Page>
                         </div>
                     </div>
             </div>
        </div>
    </div>
</template>

<script>
    export default {
        name:'cooperative',
        components: {
        },
        props: {
            loading:{
                type:Boolean,
                default:false
            },
            seamlesstitle:{
                type:Array,
                default: []
            },
            seamlessbody:{
                type:Array,
                default: []
            },
            pagination:{
                type:Object,
                default:()=>{
                    return {}
                }
            },
            title:{
                type:String,
                default: ''
            },
            heightBody:{
                type:String,
                default: 'auto'
            },
        },
        data () {
            return {
            }
        },
        computed: {
            option () {
                return {
                    step:0.2
                }
            },
        },
        watch: {
           
        },
        methods: {
            closeModelFun(){
                this.$emit('cancel','取消')
            },
            changePage(e){
                this.$emit('change',e)
            }
        },
        mounted () {
        },
        created(){
        }
    }

</script>
<style>
    .ivu-spin-fix{
         background: transparent
    }
    .ivu-page-item{
            background: rgba(8, 17, 27, 0.1);
            border-color: transparent;
    }
    .ivu-page-item-active{
        background:rgba(8, 17, 27, 0.46);
    }
    .ivu-page-item:hover {
        border-color:rgba(8, 17, 27, 0.46);
    }
    .ivu-page-total{
       color: rgba(255,255,255, 0.76); 
    }
    .ivu-page-prev{
        background: rgba(8, 17, 27, 0.1);
       border-color: transparent;
        
    }
    .ivu-page-next{
       background: rgba(8, 17, 27, 0.26);
        border-color: transparent;
        /* padding: 0px 20px; */
    }
    .ivu-page-next a{
         padding: 0px 20px;
         color: #56FEFE;
         display: inline-block;
         width: 100%;
         height: 100%;
         background: linear-gradient(to left,#56FEFE,#56FEFE) left top no-repeat,
             linear-gradient(to bottom,#56FEFE,#56FEFE) left top no-repeat,
             linear-gradient(to left,#56FEFE,#56FEFE) right top no-repeat,
             linear-gradient(to bottom,#56FEFE,#56FEFE) right top no-repeat,
             linear-gradient(to left,#56FEFE,#56FEFE) left bottom no-repeat,
             linear-gradient(to bottom,#56FEFE,#56FEFE) left bottom no-repeat,
             linear-gradient(to left,#56FEFE,#56FEFE) right bottom no-repeat,
             linear-gradient(to left,#56FEFE,#56FEFE) right bottom no-repeat;
         /*设置大小*/
         background-size: 1px 3px, 3px 1px, 1px 3px, 3px 1px, 1px 3px, 3px 1px, 1px 3px, 3px 1px;
    }
    .ivu-page-prev a{
       padding: 0px 20px;
       color: #56FEFE;
       display: inline-block;
       width: 100%;
       height: 100%;
       background: linear-gradient(to left,#56FEFE,#56FEFE) left top no-repeat,
           linear-gradient(to bottom,#56FEFE,#56FEFE) left top no-repeat,
           linear-gradient(to left,#56FEFE,#56FEFE) right top no-repeat,
           linear-gradient(to bottom,#56FEFE,#56FEFE) right top no-repeat,
           linear-gradient(to left,#56FEFE,#56FEFE) left bottom no-repeat,
           linear-gradient(to bottom,#56FEFE,#56FEFE) left bottom no-repeat,
           linear-gradient(to left,#56FEFE,#56FEFE) right bottom no-repeat,
           linear-gradient(to left,#56FEFE,#56FEFE) right bottom no-repeat;
       /*设置大小*/
       background-size: 1px 3px, 3px 1px, 1px 3px, 3px 1px, 1px 3px, 3px 1px, 1px 3px, 3px 1px;
    }
    .ivu-page-item:hover a{
        color: #56FEFE;
    } 
    .ivu-page-item a{
        color: #56FEFE;
    }
    .ivu-table th{
        background-color: #f00 !important;
    }
</style>
<style scoped lang="scss">


    .table-box{
            height: 100%;
            border-top: 2px solid #FFDB67;
        }
    .table-header{
        color: #898B9F;
        text-align: left;
        box-sizing: border-box;
        background: linear-gradient(90deg, rgba(60,215,219,0.3) 0%, rgba(50,211,224,0.3) 100%);
    }
    .table-header .table-item{
        color: #D3D3E4;
        display: inline-block;
        padding: 14px 0px;
       font-size: 14px;
       font-weight: 500;
       color: #FFFFFF;
        float: left;
    }
    .table-warp {
        position: relative;
        overflow-y: auto;
    }
    .table-warp ul{
        padding-left: 0;
        width: 100%;
    }
    .table-warp ul li{
        text-align: left;
        color: #ADD1DF;
        position: relative;
        padding:14px 0px;
        margin: 0px;
    }
    .table-warp ul li:nth-child(2n-1){
       background: linear-gradient(90deg, rgba(60,215,219,0.05) 0%, rgba(50,211,224,0.05) 100%);
    }
    .table-warp .table-item{
        display: inline-block;
        float: left;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        cursor: pointer;
        span{
           font-size: 10px;
           font-weight: 400;
           overflow: hidden;
           white-space: nowrap;
           text-overflow: ellipsis;
           display: inline-block;
           width: 100%;
           color: rgba(255,255,255,0.8)
           
        }
    }
</style>
