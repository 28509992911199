<template>
    <div class="aaa" style="position: relative">
        <Row :gutter="20">
            <Col span="15">
                <div class="title-s" >食材采购价格
                    <div class="more-button" @click="priceModel=true">More>></div>
                </div>

                <div style="position: relative">
                    <div class="problem-header clear-float">
                        <span class="problem-item"  style="width: 20%">商品名称</span>
                        <span class="problem-item"  style="width: 8%">原价格</span>
                        <span class="problem-item"  style="width: 8%">新价格</span>
                        <span class="problem-item" style="width: 6%">单位</span>
                        <span class="problem-item" style="width: 29%">中心校</span>
                        <span class="problem-item" style="width: 29%">配送商</span>
                    </div>
                    <Spin size="large" fix v-if="expireLoading"></Spin>
                    <vue-seamless-scroll
                        :class-option="{ step: 1, singleHeight: 50 }"
                        :data="expireList"
                        class="seamless-warp"
                        style="height: 100px"
                    >
                        <ul>
                            <li
                                class="clear-float"
                                v-for="(item, index) in expireList"
                                :key="item.index"
                            >
                        <span class="problem-item" style="width: 20%">{{item.product_name||'-'}}</span>
                        <span class="problem-item" style="width: 8%">{{item.old_price||'-'}}</span>
                        <span class="problem-item" style="width: 8%">{{item.price||'-'}}</span>
                        <span class="problem-item"  style="width: 6%">{{ item.unit||'-' }}</span>
                        <span class="problem-item" style="width: 29%">{{ item.section_name||'-' }}</span>
                        <span class="problem-item"  style="width: 29%">{{ item.merchant_name||'-' }}</span>
                            </li>
                        </ul>
                    </vue-seamless-scroll>
                </div>
            </Col>
            <Col span="9">
                <div class="title-s">供应商供应学校数
                    <button class="more-button" @click="merchantModel=true">More>></button>
                </div>
                <div style="position: relative">
                    <div class="problem-header clear-float">
                      
                        <span class="problem-item" style="width: 30%"
                            >供应商名称</span
                        >
                        <span class="problem-item" style="width: 45%"
                            >供应商地址</span
                        >
                        <span class="problem-item" style="width: 25%;text-align: center;"
                            >配送学校数</span
                        >
                    </div>
                    <Spin size="large" fix v-if="expireLoading"></Spin>
                    <vue-seamless-scroll
                        :class-option="{ step: 1, singleHeight: 50 }"
                        :data="unOutList"
                        class="seamless-warp"
                        style="height: 100px"
                    >
                        <ul>
                            <li
                                class="clear-float"
                                v-for="(item, index) in unOutList"
                                :key="item.index"
                            >
                              
                                <span class="problem-item" style="width: 30%">{{
                                    item.merchant_name
                                }}</span>
                                <span class="problem-item" style="width: 45%">{{
                                    item.address_details || "-"
                                }}</span>
                                  <span class="problem-item" style="width: 25%;text-align: center;">{{
                                    item.cnt || "-"
                                }}</span>
                            </li>
                        </ul>
                    </vue-seamless-scroll>
                </div>
            </Col>
        </Row>



        <Modal
            v-model="priceModel"
            title="食材采购价格明细"
            footer-hide
            width="1080px"
        >
            <div style="position: relative; text-align: center; height: 580px">
                <Spin size="large" fix v-if="loadingModal"></Spin>
                <LookMore
                    :seamlesstitle="modelTablePriceTitle"
                    :seamlessbody="modelPriceList"
                    :pagination="modelPricePagination"
                    @change="handelPriceModelChange"
                >
                <template  v-slot:proof="record">
                    <div v-if="record.item.proof&&record.item.proof.length!=0">
                        <div v-for="(child,indexC) in record.item.proof" :key="indexC">
                        <img @click="handelShowPic(child.url)"  height="20px" style="height: 20px;" :src="child.url"></img>
                        <!-- <div style="color: #fff;">{{ child.note||'-' }}</div> -->
                    </div>
                  
                    </div>
                    <div v-else>-</div>
                  
             </template>
            </LookMore>
            </div>
        </Modal>

        <Modal
            v-model="merchantModel"
            title="供应商供应学校数明细"
            footer-hide
            width="1080px"
        >
            <div style="position: relative; text-align: center; height: 580px">
                <Spin size="large" fix v-if="loadingModal"></Spin>
                <LookMore
                    :seamlesstitle="modelTableMerchantTitle"
                    :seamlessbody="modelMerchantList"
                    :pagination="modelMerchantPagination"
                    @change="handelMerchantModelChange"
                >
                <template  v-slot:company_type="record">
                    <span v-if="record.item.company_type==0">公司/企业</span>
                    <span v-if="record.item.company_type==1">个体户</span>
             </template>
            </LookMore>
            </div>
        </Modal>


        <Modal
            v-model="showPic"
            footer-hide
        >
        <div style="text-align: center;">
            <img preview style="max-height:600px;max-width: 100%;" :src="picUrl"></img>
        </div>
    </Modal>
    </div>
</template>
<script>
import { spriceApi,mcntApi } from "@/api";
import LookMore from "@/components/lookMore/index.vue";

export default {
    name: "bottomTotal",
    components: {
        LookMore
    },
    props: {
        bottomTitle: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            showPic:false,
            picUrl:"",
            title: "",
            expireList: [],
            unOutList: [],
            expireLoading: false,
            decimals: 2,
            daley: 4000,
            loadingModal:false,
            modelTablePriceTitle: [
                {
                    title: "商品名称",
                    styles: { width: "13%" },
                    textcolor: "",
                    key: "product_name",
                },
                {
                    title: "单位",
                    styles: { width: "8%" },
                    textcolor: "#0FE5F0",
                    key: "unit",
                },
                {
                    title: "原价格",
                    styles: { width: "7%" },
                    textcolor: "#1E97DB",
                    key: "old_price",
                },
                {
                    title: "新价格",
                    styles: { width: "7%" },
                    textcolor: "",
                    key: "price",
                },
                {
                    title: "状态",
                    styles: { width: "10%" },
                    textcolor: "#1E97DB",
                    key: "status_txt",
                },
                {
                    title: "调价佐证材料",
                    styles: { width: "12%" },
                    textcolor: "",
                    key: "proof",
                    slot:"proof"
                },
                {
                    title: "分类名称",
                    styles: { width: "10%" },
                    textcolor: "#FFDB67",
                    key: "cate_name",
                },

                {
                    title: "中心校",
                    styles: { width: "15%" },
                    textcolor: "#0FE5F0",
                    key: "section_name",
                },
                {
                    title: "配送商",
                    styles: { width: "15%" },
                    textcolor: "#0FE5F0",
                    key: "merchant_name",
                },
            ],
            priceModel:false,
            modelPriceList:[],
            modelPricePagination: {
                total: 0,
                current: 1,
                page_size: 10,
            },
            merchantModel:false,
            modelTableMerchantTitle: [
                {
                    title: "供应商名称",
                    styles: { width: "20%" },
                    textcolor: "",
                    key: "merchant_name",
                },
                {
                    title: "供应商地址",
                    styles: { width: "30%" },
                    textcolor: "#1E97DB",
                    key: "address_details",
                },
                {
                    title: "法人",
                    styles: { width: "10%" },
                    textcolor: "",
                    key: "company_legal",
                },
                {
                    title: "电话",
                    styles: { width: "10%" },
                    textcolor: "#1E97DB",
                    key: "mobile",
                },
                {
                    title: "单位性质",
                    styles: { width: "10%" },
                    textcolor: "",
                    key: "company_type",
                    slot:"company_type"
                },
                {
                    title: "配送学校数",
                    styles: { width: "10%" },
                    textcolor: "#FFDB67",
                    key: "cnt",
                }
            ],
            modelMerchantList:[],
            modelMerchantPagination: {
                total: 0,
                current: 1,
                page_size: 10,
            },
            
        };
    },
    created() {
        this.getInitPriceData()
        this.getInitMerchantData()
        this.getPriceDataList();
        this.getMerchantDataList();
    },
    methods: {
        showPriceMore(){},
        showMore(){},
        handelShowPic(url){
           this.showPic=true
           this.picUrl=url
        },
        handelPriceModelChange(e) {
            this.modelPricePagination.current = e;
            this.getPriceDataList();
        },
        handelMerchantModelChange(e) {
            this.modelMerchantPagination.current = e;
            this.getMerchantDataList();
        },
       async getInitMerchantData(){
        try {
            this.loading = true;
             const staticData = {
                    page: 1,
                    limit:50,
                };
                if (this.$route.query.city_id) {
                    staticData.city_id = this.$route.query.city_id;
                }
                if (this.$route.query.area_id) {
                    staticData.area_id = this.$route.query.area_id;
                }
                const resData = await mcntApi(staticData);
                this.unOutList = resData.data;
            } finally {
                this.loading = false;
            }
        },
        async getMerchantDataList(){
        try {
            this.loading = true;
            this.loadingModal = true;
             const staticData = {
                    page: this.modelMerchantPagination.current,
                    limit:10,
                };
                if (this.$route.query.city_id) {
                    staticData.city_id = this.$route.query.city_id;
                }
                if (this.$route.query.area_id) {
                    staticData.area_id = this.$route.query.area_id;
                }
                const resData = await mcntApi(staticData);
                if(this.modelMerchantPagination.current==1){
                    this.unOutList = resData.data;
                }
                this.modelMerchantList= resData.data
                this.modelMerchantPagination.total = Number(resData.count);
            } finally {
                this.loading = false;
                this.loadingModal = false;
            }
        },
        async getInitPriceData(){
            try {
            this.loading = true;
            const staticData = {
                    page: 1,
                    limit:50,
            };
            if (this.$route.query.city_id) {
                    staticData.city_id = this.$route.query.city_id;
                }
                if (this.$route.query.area_id) {
                    staticData.area_id = this.$route.query.area_id;
                }
                const resData = await spriceApi(staticData);
                this.expireList = resData.data;
            }finally{
                this.loading = false;
            }
        },
       async getPriceDataList(){
        try {
           
            this.loadingModal = true;
             const staticData = {
                    page: this.modelPricePagination.current,
                    limit:10,
                };
                if (this.$route.query.city_id) {
                    staticData.city_id = this.$route.query.city_id;
                }
                if (this.$route.query.area_id) {
                    staticData.area_id = this.$route.query.area_id;
                }
                const resData = await spriceApi(staticData);
              
                this.modelPriceList= resData.data
                this.modelPricePagination.total = Number(resData.count);
            } finally {
                this.loading = false;
                this.loadingModal = false;
            }
        },
     
    },
};
</script>
<style lang="scss">
.title-s {
    color: #fff;
    font-size: 24px;
    text-align: left;
    margin-bottom: 5px;
    display: flex;
    
.more-button{
  font-size: 12px;
  top: 0;
}
}
</style>
